/** @format */

import "../polyfill";
import $ from "jquery";
import "jquery-validation";
import "../extra_validators";

import { ComponentLoader } from "../AbstractComponent";
import { BumperComponent } from "../components/BumperComponent";
import { WideCarouselComponent } from "../components/WideCarouselComponent";
import { firePixels, getUrlParam } from "../toolkit";
import { LightboxComponent } from "../components/LightboxComponent";
import { QuantitySelectComponent } from "../components/QuantitySelectComponent";
import { createAccount } from "../requests/post";
import { getContext } from "../toolkit";
import { newPets, checkout } from "../requests/save";

new ComponentLoader([
    BumperComponent,
    QuantitySelectComponent,
    WideCarouselComponent,
]);

let featureInterval;
let link: URL;
const items = $(".lp-features__item");
const images = $(".lp-features__images > img");

const handleFeature = () => {
    if (!items.length) {
        return;
    }

    setFeatureInterval();

    items.on("click", e => {
        clearInterval(featureInterval);
        let index = items.index(e.currentTarget);
        highlightFeature(index);
    });
};

function highlightFeature(index: number) {
    items.filter(".active").removeClass("active");
    images.filter(".active").removeClass("active");

    items[index].classList.add("active");
    images[index].classList.add("active");
}

function setFeatureInterval() {
    featureInterval = setInterval(() => {
        let activeItem = items.filter(".active");
        let currentIndex = items.index(activeItem.get(0));
        let newIndex = currentIndex + 1 >= images.length ? 0 : currentIndex + 1;
        highlightFeature(newIndex);
    }, 5000);
}

function handleBumper() {
    let bumperInst = BumperComponent.getInst();

    if (!bumperInst) {
        return;
    }

    let dynamicButtons = $("[data-dynamic-button]");

    bumperInst.on("quantityChange", quantity => {
        let tagText = "Tag";

        if (quantity > 1) {
            tagText += "s";
        }

        dynamicButtons.each((idx, elem) => {
            let beforeText = elem.innerHTML;
            elem.innerHTML = beforeText.replace(/Tag(s)?/g, tagText);
        });
    });
}

const handleFAQs = () => {
    const activeClass = "active";
    const items = $(".lp-faqs__item");

    items.each(index => {
        items[index].addEventListener("click", () => {
            const isActive = items[index].classList.contains(activeClass);

            if (isActive) {
                items[index].classList.remove(activeClass);
            } else {
                items[index].classList.add(activeClass);
            }

            items.each(idx => {
                if (index !== idx) {
                    items[idx].classList.remove(activeClass);
                }
            });
        });
    });
};

const handleVideo = () => {
    const pages = $(".lp-videos__page__item");
    const container = $(".lp-videos__list > div");
    const left = $(".lp-videos__page .left");
    const right = $(".lp-videos__page .right");

    let index = 0;
    const total = pages.length;

    left?.on("click", () => {
        if (index === 0) {
            return;
        }

        pages[index].classList.remove("active");
        index = index - 1;
        pages[index].classList.add("active");
        let elemWidth = container.first().width();
        container.css("transform", `translateX(-${index * elemWidth}px)`);
    });

    right?.on("click", () => {
        if (index + 1 === total) {
            return;
        }

        pages[index].classList.remove("active");
        index = index + 1;
        pages[index].classList.add("active");
        let elemWidth = container.first().width();
        container.css("transform", `translateX(-${index * elemWidth}px)`);
    });
};

const handlePausePlay = () => {
    const videoElems = $("video");

    videoElems.on("click", e => {
        const videoElem = $(e.currentTarget);
        const videoParent = videoElem.parent();
        if (videoParent.is("[data-playing]")) {
            (videoElem.get(0) as HTMLVideoElement).pause();
            videoParent.removeAttr("data-playing");
        } else {
            videoParent.css("width", videoParent.outerWidth());
            (videoElem.get(0) as HTMLVideoElement).play();
            videoParent.attr("data-playing", "true");
        }
    });
};

const handlePetNameForm = () => {
    let form = $("[data-pet-name-form]").not("[lightbox-component] *");
    let petNameValidation = {
        required: true,
        minlength: 2,
        maxlength: 64,
        notInOtherInput: "name",
        noDuplicates: "pet_name",
        lettersOnly: true,
        hasLetters: true,
    };

    (form as any).validate({
        onKeyup: true,
        rules: {
            pet_name_0: petNameValidation,
            pet_name_1: petNameValidation,
            pet_name_2: petNameValidation,
            pet_name_3: petNameValidation,
            pet_name_4: petNameValidation,
        },
    });

    $("[data-go-to-address]").on("click", async () => {
        await submitPetForm();
    });

    $("[data-go-to-setup]").on("click", async () => {
        await submitPetForm(true);
    });

    form.on("submit", async e => {
        e.preventDefault();
        await submitPetForm();
    });
};

const submitPetForm = async (save: boolean = false) => {
    let form = $("[data-pet-name-form]").not("[lightbox-component] *");
    let button = $("[data-go-to-address], [data-go-to-setup]");

    if (!(form as any).valid() || button.find(".loader").length > 0) {
        return;
    }

    let savedButtonHtml = button.html();
    button.html("<div class='loader'></div>");

    let call = createAccount;

    if (save) {
        call = newPets;
    }

    let petNames = getPetNames();
    let response = await call(petNames).catch(() => {
        alert(window.gettext("There was an error. Please try again later."));
        button.html(savedButtonHtml);
        return null;
    });

    if (!response) {
        return;
    }

    if (save) {
        window.location.href = "/account/setup-redirect";
        return;
    }

    const { checkout_id: checkoutId, pet_refs: petRefs } = response;
    let checkoutValues = {
        tag_quantity: petNames.length.toString(),
        pet_refs: petRefs,
        checkout_id: checkoutId,
    };
    petRefs.forEach((ref: string, index: number) => {
        checkoutValues[`name_${ref}`] = petNames[index];
    });
    await checkout(checkoutId, checkoutValues, "shipping");

    link.searchParams.append("id", checkoutId);
    window.location.href = link.href;
};

const getPetNames = (): string[] => {
    let componentInst = QuantitySelectComponent.getInst() as QuantitySelectComponent;

    return componentInst
        .getNameInputs()
        .map((_, elem) => $(elem).val() as string)
        .get();
};

export function setup() {
    handleFeature();
    handleBumper();
    handleFAQs();
    handleVideo();
    handlePausePlay();
    handlePetNameForm();

    let checkoutLink = new URL("/checkout", window.location.origin);
    let flow = getUrlParam("flow");
    let subscribe = getUrlParam("subscribe");

    if (flow) {
        checkoutLink.searchParams.append("flow", flow);
    }

    if (subscribe) {
        checkoutLink.searchParams.append("subscribe", subscribe);
    }

    let checkoutLinkElem = $("[data-checkout-link]");
    checkoutLinkElem.attr("href", checkoutLink.href);
    link = checkoutLink;

    let showLoaderElem = $("[data-show-loader]");
    let loaderUrlParam = getUrlParam("loader");

    if (showLoaderElem.length || loaderUrlParam) {
        let loaderId =
            showLoaderElem.data("showLoader") || loaderUrlParam || "lp-loader";
        LightboxComponent.getDisplay(loaderId);
    }

    firePixels("ViewContent");
}
